import Axios from 'axios';

export default Axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'x-cdata-authtoken': process.env.REACT_APP_AUTHTOKEN
  }
})

export interface ICDataResponse<T> {
  value: T;
}
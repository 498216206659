import React from 'react';
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select as Sel,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core';

const Select: React.FC<ISelectProps> = (props) => {
  const {classes, label, name, value, onSelect, options} = props;
  return (
    <FormControl className={classes.select}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Sel
        value={value}
        inputProps={{id: name, name}}
        onChange={onSelect}
      >
        {options.map(({label, value}) =>(<MenuItem value={value} key={value}>{label}</MenuItem>))}
      </Sel>
    </FormControl>
  );
};

const styles = (theme: Theme) => createStyles({
  select: {
    minWidth: 200,
    margin: theme.spacing(1)
  }
});

export default withStyles(styles)(Select);

interface ISelectProps extends WithStyles<typeof styles>{
  name: string;
  label: string;
  value: string;
  options: Option[];
  onSelect(e: any): void;
}

type Option = {
  label: string;
  value: string | number;
};
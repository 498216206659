import {PBCRecord} from './dbApi';
import {ValueGetterParams} from 'ag-grid-community';
import accounting from 'accounting';

export function formatMoneyCell(prop: keyof PBCRecord) {
  return function (params: ValueGetterParams) {
    const item: PBCRecord = params.data;
    const val = accounting.formatMoney(item[prop] as string, '$', 0);
    if (item[prop] && val !== '$0') {
      return val;
    }
    return '-';
  };
}

export function formatDateCell(prop: keyof PBCRecord) {
  return function (params: ValueGetterParams) {
    const item: PBCRecord = params.data;
    return formatDate(item[prop] as string);
  };
}

export function formatDate(date?: string) {
  if (date) {
    let d = new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  }
}

export function compareDate(aVal: string, bVal: string, ...rest: any[]) {
  const inverted: boolean = rest[2];
  let a = (new Date(aVal)).valueOf();
  let b = (new Date(bVal)).valueOf();
  let result: number;

  if (a === b) {
    result = 0;
  } else if(isNaN(a)) {
    result = -1;
  } else {
    result = (a < b) ? -1 : 1;
  }
  result = inverted ? result * -1 : result;
  return result;
}

export function compareMoney(aVal: string, bVal: string, ...rest: any[]) {
  let a = accounting.unformat(aVal);
  let b = accounting.unformat(bVal);
  if (a === b) {
    return 0;
  }
  return (a < b) ? -1 : 1;
}
import ajax, {ICDataResponse} from './ajax';
import {AxiosPromise} from 'axios';

export const getPBCData = (params: CDataParams): AxiosPromise<ICDataResponse<PBCRecord[]>> =>  {
  return ajax.get('/pbcDashData', {params})
};

export const getParameters = (): AxiosPromise<ICDataResponse<Option[]>>  => {
  return ajax.get('/pbcDashParams')
};

export type Option = {
  label: string;
  value: string;
}

export type CDataParams = {
  $filter?: string;
}

export type PBCRecord = {
  projectName: string;
  projectNumber: string;
  contractNumber?: string;
  recordNo: string;
  status: string;
  step: string;
  bic: string;
  fpcSpecialist: string;
  sender: string;
  awardTo?: string;
  estimateAmount: number;
  contractAmount: number;
  substantialCompletionDate?: string;
  adDate1?: string;
  adDate2?: string;
  adDate3?: string;
  jobWalkDate?: string;
  boardAwardDate?: string;
  rfpOpenDate: string;
  bidOpenDate: string;
  ntpStartDate?: string;
  RFS_TENTATIVEAWARDDATEDOP?: string; // todo revert back to plannedBoardDate
  title: string;
  pid: number;
  workflowName: string;
}
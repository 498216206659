import React, {Component} from 'react';
import {CDataParams, getParameters, getPBCData, Option, PBCRecord} from './utils/dbApi';
import Spinner from './Components/Spinner';
import {Button, createStyles, Paper, TextField, Theme, Typography, WithStyles, withStyles} from '@material-ui/core';
import Select from './Components/Select';
import TableData from './Components/Table';

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      spin: true,
      statusFilter: 'In_Review',
      pbcData: [],
      projectSearch: '',
      specialistOptions: [],
      specialistSelect: ''
    };
  }

  componentDidMount() {
    this.getData();
    this.getParams();
  }

  getParams = async () => {
    try {
      const {data} = await getParameters();
      this.setState({
        specialistOptions: data.value
      });
    } catch (err) {

    }
  };

  createFilters = () => {
    const {startDate, endDate, statusFilter, projectSearch, specialistSelect} = this.state;
    const filters: string[] = [];
    const params: CDataParams = {};
    // check for between
    if (startDate && endDate) {
      filters.push(`RFS_TENTATIVEAWARDDATEDOP lt '${endDate} 00:00:00'and RFS_TENTATIVEAWARDDATEDOP gt '${startDate} 00:00:00'`);
    } else if (startDate) {
      filters.push(`RFS_TENTATIVEAWARDDATEDOP gt '${startDate} 00:00:00'`);
    } else if (endDate) {
      filters.push(`RFS_TENTATIVEAWARDDATEDOP lt '${endDate} 00:00:00'`);
    }
    if (statusFilter) {
      filters.push(`status eq '${statusFilter}'`);
    }
    if (projectSearch) {
      filters.push(`(contains(projectName,'${projectSearch}') or contains(projectNumber,'${projectSearch}'))`);
    }
    if (specialistSelect) {
      filters.push(`fpcSpecialist eq '${specialistSelect}'`)
    }
    params['$filter'] = filters.join(' and ');
    console.log(params);
    return params;
  };

  getData = async () => {
    this.setState({
      spin: true,
      errorMessage: '',
      pbcData: []
    });

    const params = this.createFilters();

    try {
      const {data} = await getPBCData(params);
      this.setState({
        pbcData: data.value,
        spin: false
      });
    } catch (err) {
      console.log(err);
      this.setState({
        spin: false,
        errorMessage: err.message
      });
    }
  };

  onProjectSearch = (e: any) => {
    this.setState({
      projectSearch: e.target.value
    });
  };

  handleSelect = (e: any) => {
    // @ts-ignore partial update
    this.setState({[e.target.name]: e.target.value});
  };


  render() {
    const {
      spin, errorMessage, statusFilter, startDate, endDate,
      pbcData, projectSearch, specialistOptions, specialistSelect
    } = this.state;
    const {classes} = this.props;
    return (
      <>
        <div className={classes.parameters}>
          <TextField
            label="Start Date"
            type="date"
            className={classes.select}
            InputLabelProps={{shrink: true}}
            inputProps={{name: 'startDate', id: 'start-date'}}
            value={startDate}
            onChange={this.handleSelect}
          />
          <TextField
            label="End Date"
            type="date"
            className={classes.select}
            InputLabelProps={{shrink: true}}
            inputProps={{name: 'endDate', id: 'end-date'}}
            value={endDate}
            onChange={this.handleSelect}
          />
          <TextField
            label="Project Name/Number"
            className={classes.select}
            InputLabelProps={{shrink: true}}
            inputProps={{name: 'projectSearch', id: 'project-search'}}
            value={projectSearch}
            onChange={this.onProjectSearch}
          />
          <Select
            value={statusFilter}
            label="Status"
            name="statusFilter"
            onSelect={this.handleSelect}
            options={['In_Review', 'Terminated', 'Completed'].map((v) => ({value: v, label: v.replace('_', ' ')}))}
          />
          <Select
            value={specialistSelect}
            label="FPC Specialist"
            name="specialistSelect"
            onSelect={this.handleSelect}
            options={specialistOptions}
          />
          <Button variant="outlined" color="primary" onClick={this.getData}>Search</Button>
        </div>
        <Paper className={classes.tableContainer}>
          {errorMessage && (
            <Typography
              onClick={this.getData}
              className={classes.pointer}
              variant="subtitle2"
              color="error"
            >
              {errorMessage}. Click to retry.
            </Typography>
          )}
          {spin ? <Spinner spin={spin}/> : <TableData data={pbcData}/>}
        </Paper>
      </>
    );
  }
}

const styles = (theme: Theme) => createStyles({
  parameters: {
    zIndex: 100,
    position: 'fixed',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0.5rem',
    top: 0,
    left: 0,
    right: 0,
    height: '75px',
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FAFAFA'
  },
  select: {
    minWidth: 200,
    margin: theme.spacing(1)
  },
  tableContainer: {
    top: '90px',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative'
  },
  pointer: {cursor: 'pointer'}
});

export default withStyles(styles)(App);

interface IAppProps extends WithStyles<typeof styles> {

}

interface IAppState {
  spin: boolean;
  pbcData: PBCRecord[];
  statusFilter: 'Terminated' | 'Completed' | 'In_Review';
  specialistOptions: Option[];
  specialistSelect: string;
  projectSearch: string;
  startDate?: Date;
  endDate?: Date;
  errorMessage?: string;
}
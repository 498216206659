import React from 'react';
import ReactDOM from 'react-dom';
import {ICellRendererComp, ICellRendererParams} from 'ag-grid-community';
import {Table as Tabl, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {formatDate} from '../utils/formatUtils';
import {PBCRecord} from '../utils/dbApi';

export class AdDateCell implements ICellRendererComp {
  private data: PBCRecord | undefined;
  private elem = HTMLElement;

  init(params: ICellRendererParams): void {
    this.elem = document.createElement('div');
    this.data = params.data;
    return;
  }

  getGui() {
    // @ts-ignore
    ReactDOM.render((() => {
      const item = this.data;
      return (
        <Tabl size="small" padding="none">
          <TableHead>
            <TableRow>
              <TableCell>Ad</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>{formatDate(item && item.adDate1)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2</TableCell>
              <TableCell>{formatDate(item && item.adDate2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>{formatDate(item && item.adDate3)}</TableCell>
            </TableRow>
          </TableBody>
        </Tabl>
      );
    })(), this.elem);
    return this.elem;
  }

  refresh(params: any): boolean {
    return false;
  }

}

export class ProjectInfoCell implements ICellRendererComp {
  private data: PBCRecord | undefined;
  private elem = HTMLElement;

  init(params: ICellRendererParams): void {
    this.elem = document.createElement('div');
    this.data = params.data;
    return;
  }

  getGui() {
    const title = this.data && this.data.title ? `<strong>${this.data.title}</strong></br>` : '';
    const projectName = this.data && this.data.projectNumber && this.data.projectName ? `${this.data.projectNumber}: ${this.data.projectName}</br>` : '';
    const specialist = this.data && this.data.fpcSpecialist ? `FPC Specialist: ${this.data.fpcSpecialist}` : '';
    this.elem.innerHTML = `${title} ${projectName} ${specialist}`;
    return this.elem;
  }

  refresh(params: any): boolean {
    return false;
  }

}

export class CurrentStepInfo implements ICellRendererComp {
  private data: PBCRecord | undefined;
  private elem = HTMLElement;

  init(params: ICellRendererParams): void {
    this.elem = document.createElement('div');
    this.data = params.data;
    return;
  }

  getGui() {
    let step = this.data && this.data.step ? `<strong>${this.data.step}</strong></br>` : '';
    let bic = this.data && this.data.bic ? `<strong>BIC:</strong> ${this.data.bic}` : '';
    this.elem.innerHTML = `${step} ${bic}`;
    return this.elem;
  }

  refresh(params: any): boolean {
    return false;
  }

}
import React from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {ColDef, ValueGetterParams} from 'ag-grid-community';
import {PBCRecord} from '../utils/dbApi';
import {AdDateCell, CurrentStepInfo, ProjectInfoCell} from './CustomTableCells';
import {compareDate, compareMoney, formatDate, formatDateCell, formatMoneyCell} from '../utils/formatUtils';

const LG = 220, MD = 150, SM = 120;

const Table: React.FC<INewTableProps> = (props) => {
  return (
    <div
      className="ag-theme-balham"
      style={{
        height: '90vh',
        width: '95vw' }}
    >
      <AgGridReact
        columnDefs={columnDefs}
        rowData={props.data}
        rowHeight={90}
        defaultColDef={{resizable: true, sortable: true}}
      />
    </div>
  );
};

export default Table;

interface INewTableProps {
  data: PBCRecord[];
}

const columnDefs: ColDef[] = [{
  headerName: 'Project Info', field: 'title',
  cellRenderer: ProjectInfoCell, width: LG * 2
}, {
  headerName: 'Type', field: 'workflowName', width: SM
},{
  headerName: 'BIC', cellRenderer: CurrentStepInfo, width: LG, field: 'bic'
}, {
  field: 'estimateAmount', headerName: 'Cost Estimate', valueGetter: formatMoneyCell('estimateAmount'), width: SM, type: 'numericColumn', comparator: compareMoney
}, {
  field: 'contractAmount', headerName: 'Contract Amount', valueGetter: formatMoneyCell('contractAmount'), width: SM, type: 'numericColumn', comparator: compareMoney
}, {
  field: 'ntpStartDate', headerName: 'NTP Start Date', valueGetter: formatDateCell('ntpStartDate'), width: SM, type: 'numericColumn'
}, {
  field: 'RFS_TENTATIVEAWARDDATEDOP', headerName: 'Requested Board Date', width: SM, type: 'numericColumn', comparator: compareDate,
  valueGetter: formatDateCell('RFS_TENTATIVEAWARDDATEDOP')
}, {
  field: 'substantialCompletionDate',
  headerName: 'Substantial Completion Date', width: MD, type: 'numericColumn', comparator: compareDate,
  valueGetter: formatDateCell('substantialCompletionDate')
}, {
  field: 'adDates', headerName: 'RFP/Bid Ad Dates', cellRenderer: AdDateCell, width: MD
}, {
  field: '', headerName: 'RFP/Bid Opening', width: SM, valueGetter: (params: ValueGetterParams) => {
    const item: PBCRecord = params.data;
    return formatDate(item.bidOpenDate || item.rfpOpenDate);
  }
}, {
  field: 'jobWalkDate', headerName: 'Job Walk Date', valueGetter: formatDateCell('jobWalkDate'), width: SM, type: 'numericColumn', comparator: compareDate
}, {
  field: 'awardTo', headerName: 'Awarded To', width: MD
}, {
  field: 'contractNumber', headerName: 'Contract Number', width: MD
}];